// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-page-page-tsx": () => import("./../../../src/templates/Page/Page.tsx" /* webpackChunkName: "component---src-templates-page-page-tsx" */),
  "component---src-templates-redirect-redirect-tsx": () => import("./../../../src/templates/Redirect/Redirect.tsx" /* webpackChunkName: "component---src-templates-redirect-redirect-tsx" */)
}

